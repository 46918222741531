<template>
  <v-card
    id="user-form"
    min-height="350"
  >
    <v-fab-transition>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            color="secondary"
            dark
            absolute
            small
            top
            right
            fab
            class="mb-4 me-3"
            v-on="on"
            @click="goBack"
          >
            <v-icon>
              {{ icons.mdiTableArrowLeft }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ t("tooltip.back_table") }}</span>
      </v-tooltip>
    </v-fab-transition>

    <v-tabs
      v-model="tab"
      show-arrows
      color="secondary"
    >
      <v-tab
        v-for="tab in computedTabs"
        :key="tab.icon"
        color="secondary"
        :disabled="tab.disabled"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ t(`users.${tab.title}`) }}</span>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item :kei="0">
        <tab-account
          :data-params="userData"
          :tab="tab"
          :option="option"
          :groups="computedGroups"
          @changeTab="changeTab"
        ></tab-account>
      </v-tab-item>

      <v-tab-item :kei="1">
        <tab-info
          :data-params="userData"
          :tab="tab"
          :option="option"
          @saveUser="saveUser"
          @changeTab="changeTab"
          @cancelAction="cancelAction"
        ></tab-info>
      </v-tab-item>
      <v-tab-item :kei="2">
        <tab-security
          :data-params="userData"
          :tab="tab"
          :option="option"
          @saveUser="saveUser"
          @cancelAction="cancelAction"
        ></tab-security>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
/* eslint-disable arrow-body-style */
/* eslint-disable import/no-cycle */
import {
  mdiAccountOutline,
  mdiLockOpenOutline,
  mdiInformationOutline,
  mdiBookmarkOutline,
  mdiBellOutline,
  mdiTableArrowLeft,
} from '@mdi/js'
import {
  onMounted, ref, computed,
} from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { useRouter, formatDateBar, formatDateSlash } from '@core/utils'
import { success, error } from '@core/utils/toasted'
import {
  createUser,
  editUser,
  getUser,
} from '@api'

// demos
import TabAccount from './TabAccount.vue'
import TabSecurity from './TabSecurity.vue'
import TabInfo from './TabInfo.vue'

export default {
  components: {
    TabAccount,
    TabSecurity,
    TabInfo,
  },
  setup() {
    const { t } = useUtils()
    const { route, router } = useRouter()

    const snackbarMassage = ref('')
    const dateNow = ref(formatDateSlash(new Date().toISOString().substr(0, 10)))
    const tab = ref(0)
    const option = ref(2)
    const computedOption = computed(() => option.value)
    const userId = ref(null)
    const pageData = ref()
    const filterData = ref()
    const userData = ref({
      username: null,
      password: null,
      fullname: null,
      phone_number: null,
      email: null,
      birthdate: null,
      gender: null,
      note: null,
      console_role_id: null,
      status: 'A',
    })

    // role_type: null,
    const postData = ref({
      username: null,
      password: null,
      fullname: null,
      phone_number: null,
      email: null,
      birthdate: null,
      gender: null,
      note: null,
      console_role_id: null,
    })

    // role_type: null,
    const putData = ref({
      fullname: null,
      phone_number: null,
      email: null,
      birthdate: null,
      gender: null,
      note: null,
      register_date: null,
      last_login_date: null,
      console_role_id: null,
    })
    const dataChange = ref(null)
    const groups = ref([])

    const tabsInit = ref([
      {
        title: 'account',
        icon: mdiAccountOutline,
        disabled: false,
        hide: false,
      },
      {
        title: 'info',
        icon: mdiInformationOutline,
        disabled: true,
        hide: false,
      },
      {
        title: 'security',
        icon: mdiLockOpenOutline,

        disabled: true,
        hide: false,
      },
    ])

    const computedTabs = computed({
      // eslint-disable-next-line no-return-assign
      set: val => (tabsInit.value = val),
      get: () => tabsInit.value,
    })

    const computedGroups = computed(() => groups.value.map(e => {
      return {
        ...e,
        name: e.id ? e.name : t('organizations.all_organization'),
      }
    }))

    // tabs
    const tabs = ref(tabsInit.value)

    const changeTab = (tabNumber, validForm, data) => {
      dataChange.value = data
      switch (tabNumber) {
        case 1:
          if (validForm) {
            tab.value = tabNumber
            tabs.value[1].disabled = false
          }
          break
        case 2:
          if (validForm) {
            tab.value = tabNumber
            tabs.value[2].disabled = false
          }
          break
        default:
          break
      }

      tab.value = tabNumber
    }

    const saveUser = async data => {
      let resp = null
      if (option.value === 1) {
        // role_type: data.role_type,
        postData.value = Object.assign(postData.value, {
          username: data.username,
          password: data.password,
          fullname: data.fullname,
          phone_number: data.phone_number,
          email: data.email,
          birthdate: data.birthdate ? formatDateBar(data.birthdate) : null,
          gender: data.gender,
          note: data.note,
          status: data.status,
          console_role_id: data.console_role_id,
        })
        resp = await createUser(postData.value)
      }

      if (option.value === 3) {
        putData.value = Object.assign(putData.value, {
          fullname: data.fullname,
          phone_number: data.phone_number,
          email: data.email,
          birthdate: data.birthdate ? formatDateBar(data.birthdate) : null,
          gender: data.gender,
          note: data.note,
          register_date: null,
          last_login_date: null,
          status: data.status,
          console_role_id: data.console_role_id,
        })

        resp = await editUser(putData.value, data.username)
      }

      if (!option.value) {
        router.go(-1)
      }

      if (resp.ok) {
        snackbarMassage.value = resp.message
        success(resp.message)
        router.go(-1)
      } else {
        snackbarMassage.value = resp.message.text
        error(resp.message.text)
      }
    }

    const cancelAction = () => {
      tab.value = 0
      tabs.value[1].disabled = true
      tabs.value[2].disabled = true
      userData.value = {
        username: null,
        password: null,
        fullname: null,
        phone_number: null,
        email: null,
        birthdate: null,
        gender: null,
        note: null,
        console_role_id: null,
        status: 'A',
      }
    }

    const goBack = () => {
      router.replace({
        name: 'views-user-list',
        params: {
          pageParams: pageData.value,
          filterParams: filterData.value,
        },
      })
    }

    onMounted(async () => {
      option.value = route.value.params.option || 2
      pageData.value = route.value.params.pageParams
      filterData.value = route.value.params.filterParams
      userId.value = route.value.params.id

      if (userId.value) {
        const resp = await getUser(userId.value)
        if (resp.ok) {
          userData.value = resp.data
        } else goBack()
      } else option.value = 1

      if (option.value !== 1) {
        tabs.value.forEach(e => {
          e.disabled = false
        })
      }

      if (option.value !== 1) {
        tabs.value.splice(2, 1)
      }
    })

    return {
      snackbarMassage,
      tab,
      tabs,
      option,
      userId,
      userData,
      postData,
      putData,
      dataChange,
      groups,
      dateNow,
      pageData,
      filterData,

      computedOption,
      computedTabs,
      computedGroups,

      changeTab,
      saveUser,
      cancelAction,
      goBack,

      // i18n
      t,

      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiBookmarkOutline,
        mdiBellOutline,
        mdiTableArrowLeft,
      },
    }
  },
}
</script>
