<template>
  <v-card
    flat
    class="mt-5"
  >
    <v-form ref="form">
      <div class="px-3">
        <v-card-text class="pt-5">
          <v-row class="my-6">
            <v-col
              cols="12"
              sm="8"
              md="6"
            >
              <v-text-field
                v-model="userDataComputed.password"
                :type="isNewPasswordVisible ? 'text' : 'password'"
                :append-icon="isNewPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                :label="t('users.new_password')"
                outlined
                dense
                :disabled="option!==1"
                :hint="t('users.password_hint')"
                persistent-hint
                :rules="[(option!==3 ? required : true), lengthBetweenValidator(userDataComputed.password, 6, 20)]"
                @click:append="isNewPasswordVisible = !isNewPasswordVisible"
              ></v-text-field>

              <v-text-field
                v-model="userDataComputed.cPassword"
                :type="isCPasswordVisible ? 'text' : 'password'"
                :append-icon="isCPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                :label="t('users.confirm_new_password')"
                outlined
                dense
                :disabled="option!==1"
                class="mt-3"
                :rules="[(option!==3 ? required : true), lengthBetweenValidator(userDataComputed.cPassword, 6, 20), confirmedValidator(userDataComputed.cPassword,userDataComputed.password)]"
                @click:append="isCPasswordVisible = !isCPasswordVisible"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="4"
              md="6"
              class="d-none d-sm-flex justify-center position-relative"
            >
              <v-img
                contain
                max-width="170"
                src="@/assets/images/3d-characters/pose-m-1.png"
                class="security-character"
              ></v-img>
            </v-col>
          </v-row>
        </v-card-text>

        <options-buttom-tab
          :tab-number="tab"
          :is-mode-save-edit="option !== 2"
          :info-required="true"
          :no-cancel="option === 3"
          :no-arrow="true"
          :no-save="false"
          @onCancel="onCancelButton"
          @onSave="onSaveAction"
        >
        </options-buttom-tab>
      </div>
    </v-form>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { ref, computed } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
// eslint-disable-next-line object-curly-newline
import { required, passwordValidator, confirmedValidator, lengthBetweenValidator } from '@core/utils/validation'
import OptionsButtomTab from '@/views/components/options-buttom-tab/OptionsButtomTab.vue'

export default {
  components: {
    OptionsButtomTab,
  },
  props: {
    option: {
      type: Number,
      default: 0,
    },
    tab: {
      type: Number,
      default: 0,
    },
    dataParams: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const isNewPasswordVisible = ref(false)
    const isCPasswordVisible = ref(false)
    const { t } = useUtils()
    const form = ref(null)

    const userDataComputed = computed(() => props.dataParams)

    const onCancelButton = () => {
      form.value.reset()
      emit('cancelAction')
    }

    const onSaveAction = () => {
      if (form.value.validate()) {
        emit('saveUser', userDataComputed.value)
      }
    }

    return {
      // Data
      isNewPasswordVisible,
      isCPasswordVisible,
      form,

      // Compured
      userDataComputed,

      // Methods
      onCancelButton,
      onSaveAction,

      // validations
      required,
      passwordValidator,
      confirmedValidator,
      lengthBetweenValidator,

      // i18n
      t,

      // Icons
      icons: {
        mdiKeyOutline,
        mdiLockOpenOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.two-factor-auth {
  max-width: 25rem;
}
.security-character {
  position: absolute;
  bottom: -0.5rem;
}
</style>
