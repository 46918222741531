<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text>
      <v-form
        ref="form"
        class="multi-col-validation mt-6"
      >
        <v-row>
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="userDataComputed.username"
              :label="t('users.username')"
              dense
              outlined
              :readonly="option!==1"
              :rules="[required]"
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="userDataComputed.fullname"
              :label="t('users.name')"
              dense
              outlined
              :readonly="option===2"
              :rules="[required]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="userDataComputed.email"
              :label="t('users.email')"
              dense
              outlined
              :readonly="option===2"
              :rules="[required,emailValidator]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="userDataComputed.console_role_id"
              dense
              outlined
              :label="t('roles.role')"
              :items="rolesOptions"
              item-text="name"
              item-value="id"
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[required]"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="userDataComputed.status"
              dense
              outlined
              :label="t('status.status')"
              :items="status"
              item-text="text"
              item-value="value"
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[required]"
            ></v-select>
          </v-col>

          <options-buttom-tab
            :tab-number="tab"
            :is-mode-save-edit="option !== 2"
            :no-save="true"
            :info-required="true"
            :no-cancel="option === 3"
            :no-arrow="option === 2"
            @onCancel="onCancelButton"
            @onNextTab="changeTab"
          >
          </options-buttom-tab>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiAlertOutline, mdiCloudUploadOutline, mdiMenuDown } from '@mdi/js'
import {
  ref, computed, onMounted,
} from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import { useUtils } from '@core/libs/i18n'
import { error } from '@core/utils/toasted'
import { getValidateUser, getRoles } from '@api'
import OptionsButtomTab from '@/views/components/options-buttom-tab/OptionsButtomTab.vue'

export default {
  components: {
    OptionsButtomTab,
  },
  props: {
    option: {
      type: Number,
      default: 0,
    },
    tab: {
      type: Number,
      default: 0,
    },
    dataParams: {
      type: Object,
      default: () => {},
    },
    groups: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()

    const form = ref(null)
    const userDataComputed = computed(() => props.dataParams)
    const computedOption = computed(() => props.option)
    const computedGroups = computed(() => props.groups)
    const usernameValid = ref(true)
    const usernameMessage = ref('')
    const facilitiesOptions = ref([])

    const status = computed(() => [
      { text: t('status.active'), value: 'A' },
      { text: t('status.inactive'), value: 'I' },
    ])

    const roles = computed(() => [
      { text: t('roles.administrator'), value: 'A' },
      { text: t('roles.facility_owner'), value: 'F' },
      { text: t('roles.facility_representative'), value: 'R' },
    ])
    const rolesOptions = ref([])

    const dataAcount = ref({})

    // role_type: null,
    const initialData = {
      username: null,
      fullname: null,
      email: null,
      console_role_id: null,
      group_id: null,
      status: null,
    }

    const onCancelButton = () => {
      // role_type: null,
      form.value.reset()
      dataAcount.value = Object.assign(initialData, {
        username: null,
        fullname: null,
        email: null,
        console_role_id: null,
        group_id: null,
        status: null,
      })
    }

    const changeTab = async tab => {
      if (form.value.validate()) {
        if (computedOption.value === 1) {
          const resp = await getValidateUser(userDataComputed.value.username)
          if (resp.ok) {
            usernameValid.value = true
            usernameMessage.value = null
            emit('changeTab', tab, true, userDataComputed.value)
          } else {
            usernameValid.value = false
            usernameMessage.value = resp.message.text
            userDataComputed.value.username = null
            error(usernameMessage.value)
          }
        } else emit('changeTab', tab, true, userDataComputed.value)
      }
    }

    const changeRole = () => {
      if (userDataComputed.value.console_role_id === 8) {
        userDataComputed.value.group_id = null
      }
    }

    const onSelectOrganization = async (orgId, clear = true) => {
      let resp = null
      let facilities = []
      if (clear) userDataComputed.value.facilities_ids = []
      if (orgId) {
        resp = null
      }

      if (resp && resp.ok) facilities = resp.data

      facilitiesOptions.value = facilities

      // if (userData.value.facilities_ids.length) facilitiesOptions.value = facilities.filter(e => userData.value.facilities_ids.includes(e.id))
      // else facilitiesOptions.value = facilities
    }

    onMounted(async () => {
      const response = await getRoles()
      if (response.ok) rolesOptions.value = response.data
      else error(response.message.text)

      setTimeout(async () => {
        if (userDataComputed.value.group_id) await onSelectOrganization(userDataComputed.value.group_id, false)
      }, 300)
    })

    return {
      // data
      form,
      status,
      dataAcount,
      initialData,
      roles,
      rolesOptions,
      usernameValid,
      usernameMessage,
      facilitiesOptions,

      // computeds
      userDataComputed,
      computedOption,
      computedGroups,

      // methods
      onCancelButton,
      changeTab,
      changeRole,
      onSelectOrganization,

      // validations
      required,
      emailValidator,

      // i18n
      t,

      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiMenuDown,
      },
    }
  },
}
</script>
